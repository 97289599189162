import React from 'react'

export enum BUTTON_TYPE {
  PRIMARY = 'PRIMARY',
  OUTLINED = 'OUTLINED',
}

interface ButtonInterface {
  text: string
  type?: BUTTON_TYPE
}

function computeButtonStyle(type) {
  switch (type) {
    case BUTTON_TYPE.PRIMARY:
      return 'border-beaver-green bg-beaver-green text-white hover:bg-beaver-green-dark hover:border-beaver-green-dark transition'
    case BUTTON_TYPE.OUTLINED:
      return 'border-beaver-green hover:bg-beaver-green-dark hover:text-white transition'
    default:
      return 'border-gray-500'
  }
}

export const Button = ({ type, text }: ButtonInterface) => {
  const buttonStyle = computeButtonStyle(type)
  return (
    <button
      className={`text-lg font-medium border-2 py-3 px-8 rounded-lg ${buttonStyle}`}
    >
      {text}
    </button>
  )
}
