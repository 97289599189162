import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

// components
import {
  ImageCodingBeaverTeacher,
  ImageCodingBeaver,
} from '../components/ImageComponents'
import { Button, BUTTON_TYPE } from '../components/Button'

// icons
import { FaYoutubeSquare } from '@react-icons/all-files/fa/FaYoutubeSquare'
import { FaReact } from '@react-icons/all-files/fa/FaReact'
import { FcDocument } from '@react-icons/all-files/fc/FcDocument'
import { FcLink } from '@react-icons/all-files/fc/FcLink'
import { FcWorkflow } from '@react-icons/all-files/fc/FcWorkflow'
import { FcTodoList } from '@react-icons/all-files/fc/FcTodoList'
import { FcCommandLine } from '@react-icons/all-files/fc/FcCommandLine'

import { PageLinkUrl } from '../constants'
import { FaQuoteLeft } from '@react-icons/all-files/fa/FaQuoteLeft'
import { FaQuoteRight } from '@react-icons/all-files/fa/FaQuoteRight'

interface LearningChannelCard {
  title: string
  description: string
  img: React.ReactNode
  edgeColor?: string
  startNowColor?: {
    bg: string
    text: string
  }
  cardStyles?: string
  isComingSoon?: boolean
  href?: string
}

const LearningChannelCard = ({
  cardStyles,
  title,
  description,
  edgeColor,
  img,
  isComingSoon,
  href,
  startNowColor = {
    bg: 'bg-beaver-green',
    text: '',
  },
}: LearningChannelCard) => {
  const titleComponent = (
    <>
      <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2">
        <h3 className="font-medium text-lg">{title}</h3>
        <div className="flex-none">
          {isComingSoon ? (
            <span className="px-2 py-1 rounded-md bg-yellow-300 text-gray-500">
              Coming&nbsp;Soon
            </span>
          ) : (
            <span
              className={`px-2 py-1 rounded-md ${startNowColor.bg} ${startNowColor.text}`}
            >
              เริ่มเรียนเลย
            </span>
          )}
        </div>
      </div>
      <p className="mt-4">{description}</p>
    </>
  )

  return (
    <Link to={href}>
      <div
        className={`rounded-md text-sm sm:text-base border-l-8 h-full ${
          edgeColor ? edgeColor : 'border-gray-400'
        } ${cardStyles} ${
          isComingSoon
            ? 'cursor-not-allowed'
            : 'transition-transform transform hover:-translate-x-3'
        }`}
      >
        <div
          className={`py-8 px-4 flex justify-evenly items-center h-full ${
            isComingSoon ? 'text-gray-400' : ''
          }`}
        >
          <div className="w-1/2">{titleComponent}</div>
          <div>{img}</div>
        </div>
      </div>
    </Link>
  )
}

interface TeachingStyleItemInterface {
  title: string
  description: string
  icon: React.ReactNode
}

const TeachingStyleItem = ({
  title,
  description,
  icon,
}: TeachingStyleItemInterface) => {
  return (
    <div className="text-center px-4">
      {icon}
      <h3 className="mt-6 text-lg font-medium">{title}</h3>
      <p className="mt-4 max-w-xs mx-auto">{description}</p>
    </div>
  )
}

const IndexPage = () => {
  return (
    <Layout>
      <section>
        <div className="container-xl">
          <div className="py-12 sm:py-20">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div>
                <div className="text-center md:text-left">
                  <h1 className="text-4xl md:text-6xl font-medium">
                    เรียนรู้เพื่อที่จะ
                    <br />
                    เป็น{' '}
                    <span className="text-beaver-green font-extrabold">
                      Developer
                    </span>{' '}
                    ด้วยกัน
                  </h1>
                  <p className="my-8 max-w-lg sm:my-20 text-xl">
                    แหล่งการเรียนรู้สำหรับทุกคนที่อยากเริ่มต้นพัฒนาเว็บไซต์เป็นอาชีพ
                    สอนตั้งแต่ไม่รู้เรื่องเว็บเลยจนเข้าใจและพัฒนาเว็บไซต์ด้วยตัวเองได้
                  </p>
                </div>
                <div className="flex gap-2 md:gap-4 justify-center md:justify-start">
                  <Link to="#learning-channels" className="flex-none">
                    <Button type={BUTTON_TYPE.PRIMARY} text="เริ่มเรียนเลย" />
                  </Link>
                  <Link
                    className="hidden sm:block flex-none"
                    to={PageLinkUrl.ABOUT}
                  >
                    <Button
                      type={BUTTON_TYPE.OUTLINED}
                      text="รู้จัก Coding Beaver"
                    />
                  </Link>
                </div>
              </div>
              <ImageCodingBeaver />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-beaver-blue-dark">
        <div className="container-xl section-py-normal">
          <h2 className="text-center text-3xl md:text-4xl font-medium text-beaver-blue">
            <FaQuoteLeft className="inline text-xl text-beaver-blue -mt-4" />{' '}
            เป็น <span className="text-white">Developer</span>{' '}
            ไม่ได้ยากอย่างที่คุณคิด{' '}
            <FaQuoteRight className="inline text-xl text-beaver-blue -mt-4" />
          </h2>
        </div>
      </section>
      <section>
        <div className="container-xl section-py-normal">
          <div className="flex flex-col md:flex-row justify-center items-center gap-8 md:gap-12">
            <div className="flex-none md:w-1/3 px-8 md:px-4">
              <ImageCodingBeaverTeacher />
            </div>
            {/* <div className="max-w-xl text-center md:text-left">
              <h2 className="text-3xl font-medium">
                <span className="block text-xl">ผมเชื่อว่า</span>
                <span className="text-beaver-green sm:text-beaver-blue-dark sm:border-b-4 sm:border-beaver-green">
                  ทุกคน
                </span>
                สามารถ
                <span className="text-beaver-green sm:text-beaver-blue-dark sm:border-b-4 sm:border-beaver-green">
                  เป็น Developer ได้
                </span>
              </h2>
              <p className="mt-12 text-lg max-w-sm">
                ถ้าคุณได้รับการเรียนรู้อย่างถูกต้อง คุณจะสามารถเป็น Developer
                ได้อย่างแน่นอน
              </p>
            </div> */}
            <div className="max-w-xl text-center md:text-left">
              <h2 className="text-3xl font-medium">
                {/* <span className="block text-xl">ผมเชื่อว่า</span>
                <span className="text-beaver-green sm:text-beaver-blue-dark sm:border-b-4 sm:border-beaver-green">
                  ทุกคน
                </span>
                สามารถ
                <span className="text-beaver-green sm:text-beaver-blue-dark sm:border-b-4 sm:border-beaver-green">
                  เป็น Developer ได้
                </span> */}
                เริ่มเรียนเลยวันนี้
              </h2>
              <p className="my-8 text-lg max-w-sm mx-auto md:mx-0">
                {/* ถ้าคุณได้รับการเรียนรู้อย่างถูกต้อง คุณจะสามารถเป็น Developer
                ได้อย่างแน่นอน */}
                ผมก็เคยเป็นคนที่ไม่รู้อะไรเลยเกี่ยวกับการเขียนโปรแกรมหรือการพัฒนาเว็บไซต์
                แต่ด้วยประสบการณ์ที่{' '}
                <Link to={PageLinkUrl.ABOUT} className="link">
                  เรียนวิศวฯคอมพิวเตอร์ และการทำงานในสายคอมพิวเตอร์ มานานกว่า 5
                  ปี
                </Link>{' '}
                ผมเชื่อว่าผมสามารถถ่ายทอดความรู้และความเข้าใจให้กับทุกๆคนที่สนใจ
                และผมเชื่อว่าถ้าได้รับการเรียนรู้อย่างถูกต้อง
                คุณจะต้องทำได้อย่างแน่นอน
              </p>
              <Link to="#learning-channels">
                <Button type={BUTTON_TYPE.PRIMARY} text="เริ่มเรียนเลย" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container-xl section-py-normal">
          <h2 className="text-3xl text-center font-medium">
            <span className="border-b-4 border-beaver-blue">Style</span> การสอน
          </h2>
          <div className="mt-16 flex flex-col gap-16 md:gap-4 md:flex-row justify-between">
            <TeachingStyleItem
              title="เห็นภาพรวม"
              description="สอนเนื้อหาเรียงตามลำดับ เห็นภาพรวมของที่มาที่ไปในแต่ละหัวข้อ
                รวมทั้งการนำความรู้ที่ได้ไปใช้ให้เห็นจริงๆ"
              icon={<FcWorkflow className="text-9xl mx-auto" />}
            />
            <TeachingStyleItem
              title="พื้นฐานแน่น"
              description="ไม่ข้ามเนื้อหาที่ควรต้องเรียน เพราะพื้นฐานสำคัญที่สุดสำหรับการเรียนรู้ที่ยั่งยืน"
              icon={<FcTodoList className="text-9xl mx-auto" />}
            />
            <TeachingStyleItem
              title="ลงมือทำจริง"
              description="การเรียนรู้จะเกิดผลที่สุดก็ต่อเมื่อได้ลงมือทำและฝึกฝนด้วยตัวเอง มีโปรเจคสนุกๆมากมายให้เรียนรู้จากง่ายไปยาก"
              icon={<FcCommandLine className="text-9xl mx-auto" />}
            />
          </div>
        </div>
      </section>
      <section id="learning-channels">
        <div className="container-xl section-py-normal">
          <h2 className="text-3xl text-center font-medium">
            ช่องทางการ
            <span className="border-b-4 border-beaver-green">เรียนรู้</span>
          </h2>
          <div className="mt-12 md:mt-16 grid grid-cols-1 md:grid-cols-2 gap-4">
            <LearningChannelCard
              title="Youtube Channel"
              description="มี Content มากมายหลากหลายรูปแบบให้เรียนรู้
                    ตั้งแต่ภาพรวมไปจนถึงรายละเอียด"
              img={<FaYoutubeSquare className="text-8xl text-red-500" />}
              cardStyles="shadow-md bg-pink-50"
              edgeColor="border-red-400"
              startNowColor={{
                bg: 'bg-red-400',
                text: 'text-white',
              }}
              href={PageLinkUrl.YOUTUBE}
            />
            <LearningChannelCard
              title="บทความ"
              description="เรียนรู้ผ่านการอ่าน ได้รายละเอียดเชิงลึกพร้อม Code ที่มีแนบไว้เรียบร้อย"
              img={<FcDocument className="text-8xl" />}
              cardStyles="shadow-md bg-blue-50"
              edgeColor="border-blue-400"
              startNowColor={{
                bg: 'bg-blue-400',
                text: 'text-white',
              }}
              href={PageLinkUrl.BLOG}
            />
            <LearningChannelCard
              title="แหล่งเรียนรู้เพิ่มเติม"
              description="รวบรวมแหล่งการเรียนรู้ที่เหมาะสม ให้แต่ละคนสามารถเรียนรู้เพิ่มเติมได้มากเท่าที่ต้องการ"
              img={<FcLink className="text-8xl" />}
              cardStyles="shadow-md bg-green-50"
              edgeColor="border-beaver-green"
              startNowColor={{
                bg: 'bg-beaver-green',
                text: 'text-white',
              }}
              href={PageLinkUrl.RESOURCE}
            />
            <LearningChannelCard
              title="Online Course"
              description="เนื้อหาจัดเต็มที่ถูกจัดเรียงเป็นลำดับอย่างดีให้ทุกคนสามารถเรียนรู้ได้ผ่านการฟัง และการลงมือทำ Project จริงๆมากมาย"
              img={<FaReact className="text-8xl text-gray-300" />}
              cardStyles="shadow-md bg-gray-50"
              isComingSoon
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
